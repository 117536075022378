import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { REDIRECT_BANNER } from '../../constants';
import s from './RedirectBanner.module.scss';
import ButtonNew from '../ButtonNew';
import AnchorLink from '../AnchorLink';

export const pageShouldShowBanner = (executeUrl) => {
  const currentPath = window.location.pathname;

  if (Array.isArray(executeUrl)) {
    return (
      executeUrl.includes(currentPath) ||
      executeUrl.some((url) => {
        return new RegExp(url).test(currentPath);
      })
    );
  }

  return executeUrl === currentPath || new RegExp(executeUrl).test(currentPath);
};

const propTypes = {
  pageProps: PropTypes.shape({}),
};

const defaultProps = {
  pageProps: null,
};

const RedirectBanner = ({ pageProps }) => {
  const [redirectbannerInfo, setRedirectBannerInfo] = useState(null);
  const [showRedirectBanner, setShowRedirectBanner] = useState(false);

  useEffect(() => {
    setShowRedirectBanner(false);
    const info = JSON.parse(localStorage.getItem(REDIRECT_BANNER)) || null;
    if (info) {
      setRedirectBannerInfo(info);
      /* istanbul ignore next */
      if (pageShouldShowBanner(info.executeUrl)) {
        setShowRedirectBanner(true);
        localStorage.removeItem(REDIRECT_BANNER);
      }
    }
  }, [pageProps]);

  return (
    <>
      {redirectbannerInfo && showRedirectBanner && (
        <div className={s.root}>
          <div className={s.completionText}>{redirectbannerInfo.completionText}</div>
          <div>
            <AnchorLink href={redirectbannerInfo.returnUrl}>
              <ButtonNew
                level="primary"
                theme="dark"
                label={redirectbannerInfo.ctaText}
                onClick={/* istanbul ignore next */ () => setShowRedirectBanner(false)}
              />
            </AnchorLink>
          </div>
        </div>
      )}
    </>
  );
};

RedirectBanner.propTypes = propTypes;
RedirectBanner.defaultProps = defaultProps;
export default RedirectBanner;

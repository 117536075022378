/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/no-danger */
import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import covidMessagingLevel from '../../utils/covidMessagingLevel';
import { trackEvent } from '../../utils/analytics';
import { pathnameMap } from '../../constants/location';
import s from './AlertBanner.module.scss';

const showAlert = (path, isLimitedMessaging, isUnavailableMessaging) => {
  if (isLimitedMessaging || isUnavailableMessaging) {
    if (/(about-gold|about-gold-perks|about-gold-gifts)/.test(path)) {
      return true;
    }
  }
  return false;
};

const alertMessage = (isLimitedMessaging) => {
  if (isLimitedMessaging) {
    return {
      header: 'Update: ',
      alertText: `Due to the current situation, a few location benefits are temporarily unavailable. `,
      linkUrl:
        'https://help.wizardingworld.com/hc/en-us/articles/360040592192-Covid-19-Precautions',
      linkText: 'Click here',
    };
  }
  return {
    header: 'COVID-19 (CORONAVIRUS) UPDATE: ',
    alertText:
      'Wizarding World Gold is temporarily unavailable to purchase. We apologise for any inconvenience. You can continue to check here for further updates, ',
    linkUrl: 'https://help.wizardingworld.com/hc/en-us/articles/360040592192-Covid-19-Precautions',
    linkText: 'learn more',
  };
};

const handleClick = (cleanPath, isSticky, isLimitedMessaging) => {
  trackEvent('CTA Clicked', {
    cta_text: isLimitedMessaging ? 'Click here' : 'Learn more',
    label: 'COVID-19 Banner',
    cta_location: isSticky ? 'COVID-19 Banner Sticky' : 'COVID-19 Banner Top',
    location: pathnameMap[cleanPath],
  });
  return false;
};

const propTypes = {
  visible: PropTypes.bool,
  sticky: PropTypes.bool,
};

const defaultProps = {
  visible: true,
  sticky: false,
};

const AlertBanner = ({ visible, sticky }) => {
  const { isLimitedMessaging, isUnavailableMessaging } = covidMessagingLevel();
  const router = useRouter();
  const cleanPath = router.asPath.split('?')[0].substring(1);
  if (showAlert(cleanPath, isLimitedMessaging, isUnavailableMessaging) || sticky) {
    const message = alertMessage(isLimitedMessaging);
    return (
      <div
        className={[
          s.alertBanner,
          visible ? s.visible : s.hidden,
          sticky ? s.sticky : '',
          isLimitedMessaging ? s.limited : '',
        ].join(' ')}
      >
        <div className={s.messageContainer}>
          <span className={[s.header, isLimitedMessaging ? s.unbold : ''].join(' ')}>
            {message.header}
          </span>
          <br />
          <span className={s.alertText}>{message.alertText}</span>
          <span>
            <a
              className={s.link}
              href={message.linkUrl}
              onClick={() => handleClick(cleanPath, sticky, isLimitedMessaging)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {message.linkText}
            </a>
          </span>
        </div>
      </div>
    );
  }
  return null;
};

AlertBanner.defaultProps = defaultProps;
AlertBanner.propTypes = propTypes;
export default AlertBanner;

/* eslint-disable react/prop-types */
import React from 'react';
// import App from 'next/app'
import { ApolloProvider } from '@apollo/client';
import { gsap, CSSPlugin } from 'gsap';
import appWithI18n from 'next-translate/appWithI18n';
import BugsnagWrapper from '../src/components/BugsnagWrapper';
import RouterLoader from '../src/components/RouterLoader';
import '../src/styles/app.scss';
import '../src/styles/slick-carousel/slick-carousel.scss';
import '../src/styles/one-trust/footer-link-overrides.scss';
import { initSegmentQueue } from '../src/utils/analytics';
import AlertBanner from '../src/components/AlertBanner';
import RedirectBanner from '../src/components/RedirectBanner';
import SegmentIdentify from '../src/components/SegmentIdentify';
import UserProvider from '../src/contexts/user-context';
import FeatureFlagProvider from '../src/contexts/feature-flag-context';
import NavProvider from '../src/contexts/nav-context';
import LocaleRouter from '../src/components/LocaleRouter';
import 'focus-visible';
import useApollo from '../src/hooks/useApollo';
import i18nConfig from '../i18n';

gsap.registerPlugin(CSSPlugin);

if (process.browser) {
  initSegmentQueue();
}

function MyApp({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps.initialState);
  const { navData, footerData } = pageProps;
  return (
    <>
      <LocaleRouter />
      <RouterLoader />
      <BugsnagWrapper>
        <ApolloProvider client={apolloClient}>
          <FeatureFlagProvider>
            <NavProvider value={{ navData, footerData }}>
              <UserProvider>
                <SegmentIdentify />
                <AlertBanner />
                <RedirectBanner pageProps={pageProps} />
                <div style={{ position: 'relative' }}>
                  <Component {...pageProps} />
                </div>
              </UserProvider>
            </NavProvider>
          </FeatureFlagProvider>
        </ApolloProvider>
      </BugsnagWrapper>
      <div id="full-screen-effects" />
    </>
  );
}

// TODO: remove the appWithI18n HOC when default next-translate webpack loader is used
// after webpack v4 -> v5 upgrade.
export default appWithI18n(MyApp, {
  ...i18nConfig,
  skipInitialProps: true,
});

// Other domain routes
export const APPLE_APP_URL = 'https://apps.apple.com/gb/app/wizarding-world/id1427926466';
export const HELP_URL = 'https://help.wizardingworld.com';
export const HELP_HC_URL = ' https://help.wizardingworld.com/hc/en-us';
export const ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=com.wwdfe.goog.wizardingworld&hl=en_GB';
export const FORM_STACK_URL = 'https://wizardingworld.formstack.com/forms/fan_club_ideas';
export const FAQ = 'https://help.wizardingworld.com/';
export const MANAGE = 'https://help.wizardingworld.com/hc/en-us/articles/360033185352';
export const MIGRATE_LEARN_MORE =
  'https://help.wizardingworld.com/hc/en-us/articles/360028490491-What-has-happened-to-my-Pottermore-account-';
export const CONTACT_US = 'https://help.wizardingworld.com/hc/en-us/requests/new';
export const CHILD_REGISTRATION_FAQS =
  'https://help.wizardingworld.com/hc/en-us/sections/360006055692-Child-Account';

// AUTH ROUTES
export const REGISTER = '/register';
export const REGISTER_VERIFY = '/register/verify';
export const LOGIN = '/login';
export const NEWSLETTER_OPT_IN = '/register/newsletter-opt-in';
export const PARENTAL_CONSENT = '/parental-consent';
export const FORGOT_PASSWORD = '/forgot-password';
export const MIGRATE = '/migrate';
export const POTTERMORE_REGISTER = '/migrate/register';
export const POTTERMORE_VERIFY = '/migrate/verify';

// profile
export const PROFILE = '/profile';
export const PROFILE_PET_SHOP = '/profile/pet-shop';
export const PROFILE_GRYFFINDOR = '/profile/gryffindor';
export const PROFILE_SLYTHERIN = '/profile/slytherin';
export const PROFILE_HUFFLEPUFF = '/profile/hufflepuff';
export const PROFILE_RAVENCLAW = '/profile/ravenclaw';
export const PROFILE_WAND = '/profile/wand';
export const PROFILE_PATRONUS = '/profile/patronus';
export const PROFILE_PATRONUS_INTRO = '/profile/patronus-intro';
export const PROFILE_SETTINGS = '/profile/settings';
export const PROFILE_SETTINGS_NAME = '/profile/settings/name';
export const PROFILE_SETTINGS_PASSWORD = '/profile/settings/password';
export const PROFILE_SETTINGS_DELETE_ACCOUNT = '/profile/settings/delete-account';
export const PORTRAIT_MAKER = '/portrait-maker';

// sorting & patronus
export const NEXT_PATRONUS = '/patronus';

// Fact File Index routes
export const FACT_FILE_INDEX = `/fact-file`;
export const FACT_FILE_CHARACTERS_AND_PETS = `/fact-file/characters-and-pets`;
export const FACT_FILE_OBJECTS = `/fact-file/objects`;
export const FACT_FILE_LOCATIONS = `/fact-file/locations`;
export const FACT_FILE_POTIONS_AND_PLANTS = `/fact-file/plants-and-potions`;
export const FACT_FILE_CREATURES = `/fact-file/creatures`;
export const FACT_FILE_SPELLS = `/fact-file/spells`;
export const FACT_FILE_MAGICAL_MISCELLANY = `/fact-file/magical-miscellany`;

export const ITEM_UNLOCK = '/item-unlock';

// my.wizardingworld.com routes
export const GOLD_GIFT = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/gift-gold`;
export const WW_LOGOUT = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/logout`;
export const MY_PATRONUS = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/patronus`;
export const MY_MIGRATE = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/migrate`;
export const WW_PASSPORT = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/passport`;
export const DELETE_ACCOUNT = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/passport/deleteaccount`;
export const WW_SORTING_HAT = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/sorting-hat`;
export const WW_SORTING_HAT_DIRECT = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/sorting-hat/question-1`;
export const WW_WAND = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/wand/question-1`;
export const WW_PATRONUS = `${process.env.REACT_APP_MY_WIZARDING_WORLD_URL}/patronus`;

// Local repo routes
export const HOME_URL = '/';
export const SEARCH = '/search';
export const ERROR_404 = '/404';
export const ABOUT_PASSPORT = '/about-passport';
export const LEARN_ABOUT = '/about-us';
export const AFFILIATES = '/affiliates';
export const CHILDREN_PRIVACY_POLICY = '/childrens-privacy-policy';
export const DISCOVER_BOOKS = '/discover/books';
export const DISCOVER_FILMS = '/discover/films';
export const DISCOVER_PORTKEY_GAMES = '/discover/portkey-games';
export const DISCOVER_EXPERIENCES = '/discover/experiences';
export const DISCOVER_YOUR_HOUSE = '/news/discover-your-hogwarts-house-on-wizarding-world';
export const DISCOVER_CURSED_CHILD =
  '/discover/books/harry-potter-and-the-cursed-child-parts-one-and-two-screenplay';
export const FEATURES_URL = '/features';
export const HARRY_POTTER_FAN_CLUB = '/harry-potter-fan-club';
export const HARRY_POTTER_FAN_CLUB_MEDIA = '/harry-potter-fan-club-media';
export const LEGALS_URL = '/legals';
export const MUGGLE_DEVICES = '/muggle-devices';
export const NEWS_URL = '/news';
export const SORTING_HAT_OUTCOME = '/outcome/:house';
export const LOCALIZED_HOUSE_RESULTS = '/house-results';
export const PRESS_URL = '/press';
export const PRIVACY_URL = '/privacy';
export const PRIVACY_CHOICES = '/privacy#choices';
export const PRIVACY_SETTINGS = '/privacy-settings';
export const QUIZ_URL = '/quiz';
export const STORE_URL = '/store';
export const STORE_PASSTHROUGH = '/store?passthrough=true';
export const TERMS_URL = '/terms';
export const TERMS_US_CA = '/terms/terms-us-ca';
export const TERMS_ROW = '/terms/terms-row';
export const PURCHASE_TERMS_BASE = '/terms/gold-purchase-terms'; // country code appended elsewhere
export const WRITING_BY_JK_ROWLING = '/writing-by-jk-rowling';
export const PRIDE_HUFFLEPUFF = '/collections/hufflepuff';
export const PRIDE_GRYFFINDOR = '/collections/gryffindor';
export const PRIDE_RAVENCLAW = '/collections/ravenclaw';
export const PRIDE_SLYTHERIN = '/collections/slytherin';
export const HP_AT_HOME_LATEST = '/collections/harry-potter-at-home';
export const CURSED_CHILD_COLLECTION = '/collections/cursed-child-collection';
export const HP_AT_HOME_READING_GUIDE = '/collections/first-year-readers';
export const HP_AT_HOME_PUZZLES = '/collections/first-year-challenges';
export const HP_AT_HOME_CRAFT = '/collections/crafting-magic';
export const STARTING_HARRY_POTTER = '/collections/starting-harry-potter';
export const SORTING_HAT_CUSHION = '/access';
export const YOUNG_VISITORS = '/collections/young-visitors';
export const LEGACY_CONNECT = '/legacyconnect';
export const QUIDDITCH_CHAMPIONS_CONNECT = `/quidditch-connect`;
export const USERLINK_UNLINK = '/userlink/unlink';
export const USERLINK_RESYNC = '/userlink/resync';
export const SORTING_HAT = '/sorting-hat';
export const SORTING_HAT_FIRST_QUESTION = '/sorting-hat/question-1';

export const NO_LOADER_ROUTES = [PROFILE, PARENTAL_CONSENT, REGISTER];

/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  external: PropTypes.bool,
  disabled: PropTypes.bool,
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  page: PropTypes.string,
  prefetch: PropTypes.bool,
};

const defaultProps = {
  external: false,
  children: <></>,
  disabled: false,
  marginTop: 0,
  marginBottom: 0,
  page: null,
  prefetch: true,
};

const getPagePath = (path) => {
  const noHashPath = path.split('#')[0];
  const [basePath, ...pathArray] = noHashPath.substring(1).split('/');
  if (pathArray.length === 0) {
    return noHashPath;
  }
  if (['news', 'features', 'quiz'].includes(basePath)) {
    return `/${basePath}/[articleId]`;
  }
  if (['writing-by-jk-rowling', 'collections'].includes(basePath)) {
    return `/${basePath}/[externalId]`;
  }
  if (['terms'].includes(basePath)) {
    return `/${basePath}/[ancillaryId]`;
  }
  if (basePath === 'discover') {
    if (pathArray.length === 1) {
      return `/${basePath}/[listingType]`;
    }
    return `/${basePath}/[listingType]/[externalId]`;
  }
  return noHashPath;
};

const AnchorLink = ({
  href,
  external,
  children,
  disabled,
  marginTop,
  marginBottom,
  page,
  prefetch,
  ...props
}) => {
  // Change both wizardingworld and harrypotter urls to relative paths
  const wizardingWorldRegex = /^(https?:\/\/)?(www\.)?(dev|qa|rc)?\.?(wizardingworld|harrypotter)\.com/;
  if (wizardingWorldRegex.test(href)) {
    const doubleslashIndex = href.indexOf('//');
    const index = href.indexOf('/', doubleslashIndex + 2);
    href = href.substring(index);
  }

  if (disabled) {
    return (
      <span
        style={{
          marginTop,
          marginBottom,
        }}
        {...props}
      >
        {children}
      </span>
    );
  }
  if (external || href.substr(0, 4) === 'http' || href.substr(0, 2) === '//') {
    return (
      <a
        href={href}
        style={{
          marginTop,
          marginBottom,
        }}
        {...props}
      >
        {children}
      </a>
    );
  }

  const pagePath = page || getPagePath(href);
  // Add prefetch prop only if false to avoid warning
  const prefetchProp = {
    prefetch: false,
  };

  return (
    <Link href={pagePath} as={href} {...(prefetch ? {} : prefetchProp)}>
      <a
        {...props}
        style={{
          marginTop,
          marginBottom,
        }}
      >
        {children}
      </a>
    </Link>
  );
};

AnchorLink.propTypes = propTypes;
AnchorLink.defaultProps = defaultProps;
export default AnchorLink;

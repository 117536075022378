// eslint-disable-next-line import/prefer-default-export
export const LOCATION_HOME = 'Wizarding World Home';
export const LOCATION_ABOUT_US = 'About Us';
export const LOCATION_ABOUT_PASSPORT = 'About Passport';
export const LOCATION_ABOUT_PRESS = 'Press';
export const LOCATION_ABOUT_TERMS = 'Terms';
export const LOCATION_PRIVACY = 'Privacy';
export const LOCATION_CHILDRENS_PRIVACY_POLICY = 'childrens-privacy-policy';
export const LOCATION_LEGALS = 'Legals';
export const LOCATION_NEWS = 'News Hub';
export const LOCATION_FEATURES = 'Features Hub';
export const LOCATION_QUIZ = 'Quiz Hub';
export const LOCATION_WRITING_BY_JK_ROWLING = 'JK Rowling Hub';
export const LOCATION_ABOUT_THE_FAN_CLUB = 'Fan Club';
export const LOCATION_GIFT_GOLD = 'About Gold Gifts';
export const LOCATION_ABOUT_GOLD = 'About Gold';
export const LOCATION_ABOUT_GOLD_PERKS = 'About Gold Perks';
export const LOCATION_GOLD_UNAVAILABLE = 'Gold Unavailable';
export const LOCATION_DISCOVER = 'discover';
export const LOCATION_PROFILE = 'Profile';

export const pathnameMap = {
  'about-passport': LOCATION_ABOUT_PASSPORT,
  'childrens-privacy-policy': LOCATION_CHILDRENS_PRIVACY_POLICY,
  'writing-by-jk-rowling': LOCATION_WRITING_BY_JK_ROWLING,
  'about-the-fan-club': LOCATION_ABOUT_THE_FAN_CLUB,
  'about-gold-gifts': LOCATION_GIFT_GOLD,
  'about-gold': LOCATION_ABOUT_GOLD,
  'about-gold-perks': LOCATION_ABOUT_GOLD_PERKS,
  'gold-unavailable': LOCATION_GOLD_UNAVAILABLE,
  discover: LOCATION_DISCOVER,
  home: LOCATION_HOME,
  news: LOCATION_NEWS,
  features: LOCATION_FEATURES,
  about: LOCATION_ABOUT_US,
  press: LOCATION_ABOUT_PRESS,
  terms: LOCATION_ABOUT_TERMS,
  privacy: LOCATION_PRIVACY,
  quiz: LOCATION_QUIZ,
  legals: LOCATION_LEGALS,
  profile: LOCATION_PROFILE,
};

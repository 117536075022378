import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import s from './RenderAnimation.module.scss';

const DynamicLottie = dynamic({
  loader: () => import('react-lottie-player'),
  ssr: false,
});

const propTypes = {
  title: PropTypes.string,
  animation: PropTypes.string,
  animationData: PropTypes.shape({}),
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaRole: PropTypes.string,
  loop: PropTypes.bool,
  isStopped: PropTypes.bool,
  complete: PropTypes.func,
  goTo: PropTypes.number,
  segmentsEnabled: PropTypes.bool,
  segments: PropTypes.arrayOf(PropTypes.number),
  speed: PropTypes.number,
  goToDirectEnabled: PropTypes.bool,
};

const defaultProps = {
  title: '',
  animation: '',
  animationData: {},
  className: '',
  ariaLabel: 'Animation',
  ariaRole: 'img',
  loop: true,
  goTo: 0,
  isStopped: false,
  complete: () => {},
  segmentsEnabled: false,
  segments: [],
  speed: 1,
  goToDirectEnabled: false,
};

const RenderAnimation = ({
  title,
  animation,
  animationData,
  className,
  ariaLabel,
  ariaRole,
  loop,
  isStopped,
  complete,
  goTo,
  segmentsEnabled,
  segments,
  speed,
  goToDirectEnabled,
}) => {
  const segment = useRef(0);
  const handleFrameUpdate = (frame) => {
    segment.current = frame.currentTime;
  };

  // enables ability to go to a specific frame directly without altering previously used logic
  let resolvedGoTo = isStopped ? segment.current : goTo;
  if (goToDirectEnabled) {
    resolvedGoTo = goTo;
  }
  return (
    <div className={s[animation]}>
      {title.length > 0 ? <div className={s.title}>{title}</div> : ''}
      <div className={[s.inner, className].join(' ')}>
        <DynamicLottie
          aria-label={ariaLabel}
          role={ariaRole}
          onComplete={() => {
            complete();
          }}
          animationData={animationData}
          play={!isStopped}
          loop={loop}
          goTo={resolvedGoTo}
          segments={segmentsEnabled && segments}
          speed={speed}
          onEnterFrame={(frame) => handleFrameUpdate(frame)}
        />
      </div>
    </div>
  );
};

RenderAnimation.propTypes = propTypes;
RenderAnimation.defaultProps = defaultProps;

export default RenderAnimation;

const covidMessagingLevel = () => {
  const isLimitedMessaging = process.env.REACT_APP_COVID_MESSAGING === 'LIMITED';
  const isUnavailableMessaging = process.env.REACT_APP_COVID_MESSAGING === 'UNAVAILABLE';
  const isDefaultMessaging =
    process.env.REACT_APP_COVID_MESSAGING !== 'LIMITED' &&
    process.env.REACT_APP_COVID_MESSAGING !== 'UNAVAILABLE';

  return {
    isLimitedMessaging,
    isUnavailableMessaging,
    isDefaultMessaging,
  };
};

export default covidMessagingLevel;
